.dragging {
  background: yellow;
  border: 0px;
}

.course {
  min-height: 200px;
  height: 100%;
}

.section-droppable {
  min-height: 40px;
  border: 1px dashed grey;
  flex-grow: 1;
}
