.ant-image-preview-mask {
  z-index: 1079 !important;
  background-color: rgba(0, 0, 0) !important;
}

.ant-image-preview-wrap {
  z-index: 1080 !important;
}

.ant-image-preview-switch-right .anticon-right {
  font-size: 4rem !important;
}

.ant-image-preview-switch-left .anticon-left {
  font-size: 4rem !important;
}
