.custom-tooltip {
  position: relative;
}

.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  position: absolute;
  z-index: 99;
  top: 110%;
}

.custom-tooltip .custom-tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
}

/* https://codepen.io/AlicanErdurmaz/pen/QWjvmgr */
/* .custom-tooltip[data-tool-tip] {
  position: relative;
}
.custom-tooltip[data-tool-tip]:focus::before,
.custom-tooltip[data-tool-tip]:hover::before {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
.custom-tooltip[data-tool-tip]::before {
  text-align: center;
  content: attr(data-tool-tip);
  visibility: hidden;
  opacity: 0;
  min-width: 100%;
  width: max-content;
  height: max-content;
  position: absolute;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  padding: 4px 8px;
  background-color: #5a5a5a;
  color: white;
  border-radius: 4px;
  font-weight: 500;
  white-space: nowrap;
  transition: opacity ease-out 150ms, bottom ease-out 150ms;
}
.custom-tooltip[data-position="top"]::before {
  bottom: 100%;
}
.custom-tooltip[data-position="right"]::before {
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.custom-tooltip[data-position="bottom"]::before {
  top: 100%;
}
.custom-tooltip[data-position="left"]::before {
  right: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
} */
