:root {
  --cura-green: #72bb53;
  --cura-light-green: #92d36e;
  --cura-lighter-green: #cbe8ba;
  --cura-red: #ff8a84;
  --cura-light-red: #ffb4b0;
  --gray-400: #e0e6ed;
  --gray-500: #d3dce6;
}

body {
  margin: 0;
  height: 100vh;
}

#root {
  height: 100%;
}

.app {
  display: flex;
  position: relative;
}

.AuthPanel {
  text-align: left;
  padding: 100px 20px 100px 20px;
  width: 650px;
  display: inline-block;
}

.InputForm {
  padding: 50px 0 0 0;
}

.ck-content {
  color: black;
}

.ck-content p:last-child {
  margin-bottom: 0;
}

.ck-editor__editable_inline {
  min-height: 400px;
}

.top-menu {
  position: fixed;
  left: 0;
  top: 0;
  background: transparent;
  width: 100%;
  z-index: 100;
}

.partly-transparent-bg {
  background: rgba(246, 246, 246, 0.9);
}

.content-panel {
  margin-top: 3rem;
}

.right-panel {
  flex-grow: 1;
  overflow-y: scroll;
}

.removecaret .dropdown-toggle:after {
  display: none;
}

.image-wrapper-43 {
  position: relative;
  overflow: hidden;
}

.image-wrapper-43:after {
  content: "";
  display: block;
  padding-top: 66.66%;
}

.image-wrapper-43 img {
  width: auto;
  height: 100%;
  max-width: none;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.cursor-pointer {
  cursor: pointer;
}

.react-toast-notifications__container {
  overflow-y: hidden !important;
}

.table-td-breakword {
  white-space: normal !important;
  word-break: break-word;
}

.management-table-td {
  max-width: 18rem;
}

.dropdown-menu {
  min-width: 12rem !important;
}

.btn {
  white-space: nowrap;
}

/* TODO: Remove when Bootstrap 5 is in place. This is a temporary solution for CR-181 */
.accordion .card-header:after {
  content: "" !important;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.hover-white:hover {
  background-color: white;
}

.hover-text-warning:hover {
  color: var(--warning) !important;
}

.hover-gray-400:hover {
  background-color: var(--gray-400) !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--warning);
}

.course-task-view-container {
  width: 100%;
}

/* TODO: This is a temporary solution for lg size screens. Replace when better solution is found */
@media (min-width: 992px) {
  .course-task-view-container {
    width: calc(100% - 24rem);
  }
}

.white-nav-tab .nav-tabs .nav-item.show .nav-link,
.white-nav-tab .nav-tabs .nav-link.active {
  color: white !important;
  background-color: transparent !important;
  border-color: transparent transparent white !important;
}

.white-nav-tab .nav-tabs .nav-item {
  margin-bottom: 0;
}

.white-nav-tab .nav-tabs .nav-link:not(.active):hover {
  color: white;
}

.btn-outline-warning:hover {
  color: white !important;
}

.slider-button:hover {
  z-index: 3 !important;
}

.hidden-scroll-bar::-webkit-scrollbar {
  display: none;
}

.no-text-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.min-height-500-table .ant-table-body {
  min-height: 500px;
}

.text-pre-wrap {
  white-space: pre-wrap !important;
}

.form-group.required .form-label:after {
  content: "*";
  color: red;
}

.border-3 {
  border-width: 3px !important;
}

.dropdown-toggle:after {
  display: none !important;
}
